import React, { useEffect, useState } from 'react';
import Layout, { Content } from '../../components/Layout';
import Navigation from '../../components/mybunac/navigation';
import { useAuth } from '../../hooks/useAuth';
import { useProfile } from '../../hooks/useProfile';
import { useApplication } from '../../hooks/useApplication';
import { Login, NotVerified } from '../../components/login';
import Form from '../../components/mybunac/DealOverview/refund-recording';
import Loading from '../../components/common/Loading';
import Success from '../../components/forms/ThankYou';

const RefundRecording = ({ data, pageContext }) => {
  const { isAuthenticated, user } = useAuth();
  const { profile } = useProfile();
  const {
    isLoading,
    application,
    setApplicationId,
    refundPayment,
  } = useApplication();
  const [hasSucceeded, setHasSucceeded] = useState(false);

  const submit = async ({ paymentType, products, amount, date, orderId }) => {
    try {
      await refundPayment({
        type: paymentType,
        amount,
        products,
        paymentId: orderId,
      });

      await setHasSucceeded(true);
    } catch (error) {
      await setHasSucceeded(false);
      console.log(`could not refund payment: ${error.message}`);
    }
  };
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const applicationId = window.location.hash.substr(1);
    setApplicationId(applicationId);
  }, [isAuthenticated]);

  if (!isLoading) {
    if (!isAuthenticated) {
      return <Login />;
    }

    if (!user.email_verified || profile.role !== 'agent') {
      return <NotVerified />;
    }
  }

  return (
    <Layout title={'Refund'}>
      <Navigation />
      <Content>
        {!!isLoading && <Loading />}
        {!isLoading && !hasSucceeded && (
          <Form
            application={application}
            products={application.products}
            onSubmit={submit}
          />
        )}
        {!isLoading && !!hasSucceeded && (
          <Success
            title="Successfully recorded!"
            text=""
            button="Go back to Deal Overview"
          />
        )}
      </Content>
    </Layout>
  );
};

export default RefundRecording;