import React, { useState, useEffect } from 'react';
import Button from '../../common/Button';
import { SubHeadline } from '../../common/Headline';
import { Grid, Select, TextField } from '@material-ui/core';
import Breadcrumbs from '../Breadcrumbs';
import Text from '../../common/Text';
import ProductSelect from '../../common/productselect';
import ConfirmationPopUp from './confirmation-popup';
import {
  formatCurrency,
  formatDate,
  DEFAULT_DATE_FORMAT,
} from '../../../lib/formatter';
import { KeyboardDatePicker } from '@material-ui/pickers';

const toStringProducts = products => {
  let productsString = '';

  products.forEach((product, index, array) => {
    productsString += `${product.title} ${formatCurrency(product.price)}${
      index < array.length - 1 ? ' | ' : ''
    }`;
  });

  return productsString;
};

const REFUND_TYPES = [
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Realex',
    value: 'realex',
  },
  {
    label: 'Bank Transfer',
    value: 'bank_xfer',
  },
];

export default ({ application, products, onSubmit }) => {
  const [name, setName] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(formatDate(new Date(), 'yyyy-MM-dd'));
  const [paymentType, setPaymentType] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const submit = async () => {
    if (onSubmit) {
      await onSubmit({
        name,
        paymentType,
        products: selectedProducts,
        amount,
        date,
        orderId,
      });
    }
  };

  const changeValue = setState => e => {
    setState(e.target.value);
  };

  const calculateTotalPrice = products => {
    let total = 0;
    products.forEach(({ price }) => {
      total += price;
    });

    return total;
  };
  useEffect(() => {
    if (application) {
      setName(application.person_id.name);
      setBreadcrumbs([
        {
          label: 'Deal Overview',
          to: '/mybunac/refund',
        },
        {
          label: application.programmeDetails.title,
        },
        {
          label: 'Refund Recording',
        },
      ]);
    }
  }, [application, products]);

  useEffect(() => {
    const totalPrice = calculateTotalPrice(selectedProducts);
    setAmount(totalPrice);
  }, [selectedProducts]);

  useEffect(() => {
    setIsValid(
      name &&
        application &&
        selectedProducts.length &&
        date &&
        orderId &&
        amount &&
        paymentType
    );
  }, [application, selectedProducts, date, orderId, amount, paymentType]);

  if (!application) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SubHeadline>Record Refund</SubHeadline>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Text>Name</Text>
          </Grid>
          <Grid item xs={8} align="right">
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={name}
              onChange={changeValue(setName)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Text>Refund Type</Text>
          </Grid>
          <Grid item xs={8} align="right">
            <Select
              native
              variant="outlined"
              size="small"
              fullWidth
              value={paymentType}
              onChange={changeValue(setPaymentType)}
              displayEmpty
            >
              <option disabled selected>
                Please select
              </option>
              {REFUND_TYPES.map((type, index) => (
                <option key={`refundoption${index}`} value={type.value}>
                  {type.label}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Text>Products to Refund</Text>
          </Grid>
          <Grid item xs={8}>
            <ProductSelect
              products={application.products}
              currency={application.currency}
              onChange={setSelectedProducts}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Text>Amount</Text>
          </Grid>
          <Grid item xs={8} align="right">
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={amount}
              onChange={changeValue(setAmount)}
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Text>Date</Text>
          </Grid>
          <Grid item xs={8} align="right">
            <KeyboardDatePicker
              clearable
              value={date}
              onChange={setDate}
              fullWidth
              format={DEFAULT_DATE_FORMAT}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Text>Order ID</Text>
          </Grid>
          <Grid item xs={8} align="right">
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderId}
              onChange={changeValue(setOrderId)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ConfirmationPopUp
          isDisabled={!isValid}
          submitText="Confirm"
          triggerButtonText="Record Refund"
          onSubmit={submit}
          dataRows={[
            {
              label: 'Name',
              value: name,
            },
            {
              label: 'Paid Products',
              value: toStringProducts(selectedProducts),
            },
            {
              label: 'Refund Type',
              value: paymentType,
            },
            {
              label: 'Currency',
              value: application.currency,
            },
            {
              label: 'Amount',
              value: formatCurrency(amount),
            },
            {
              label: 'Date',
              value: formatDate(new Date(date)),
            },
            {
              label: 'Order ID',
              value: orderId,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
